
import { mdiAccountLock } from '@mdi/js'
import { mapActions, mapGetters } from 'vuex'
import { categories } from '@/constants/categories'
export default {
  data() {
    return {
      mdiAccountLock,
      drawer: false,
      routes: [],
      fabButton: false,
    }
  },
  mounted() {
    // With firebase auth, SSR says isAdmin is false but when
    // rendered on client, isAdmin is true. This is because auth state is stored client side
    // but this causes the DOM tree to be different on SSR and Client side
    // this is the workaround to only show the fab button client side
    this.fabButton = this.authUser.isAdmin
  },
  async created() {
    try {
      this.getUserCart()
    } catch (error) {
      console.error(error)
    }
  },
  computed: {
    ...mapGetters('cart', ['cartLength']),
    ...mapGetters('auth', ['authUser']),
    admin() {
      if (!this.authUser) return false
      return this.authUser.isAdmin ? true : false
    },
  },
  methods: {
    ...mapActions('cart', ['getUserCart']),
    toggleDrawer() {
      this.drawer = !this.drawer
    },
    closeDrawer() {
      this.drawer = false
    },
  },
  async fetch() {
    // Get all products according to categories and add pending promises to array
    const productsByCategoriesQuery = categories.map((c) => {
      return this.$fire.firestore
        .collection('products')
        .where('category', '==', c.value)
        .where('visible', '==', true)
        .orderBy('priority', 'asc')
        .get()
    })

    const allSubcategoriesPromise = await this.$fire.firestore
      .doc('admin/subcategories')
      .get()

    // Wait for the subcats to load in
    const { subcategories } = allSubcategoriesPromise.data()
    console.log(subcategories)
    // Wait for all the promises to resolve
    const productsByCategories = await Promise.all(productsByCategoriesQuery)

    // Create object to be used in routes array
    const d = {
      route: '',
      name: '',
      isDisabled: false,
      subMenu: [
        /*         
        {
          subMenuRoute: '/gifts-and-bundles-100',
          submenuItem: '$100',
        }, 
        */
        //leaving this commented just to show structure
      ],
    }
    const p = productsByCategories.map((products, index) => {
      // Create a local copy of the route object for use inside this function
      const obj = { ...d }

      // Assign the navigation name to the corresponding name from [categories] based on index
      obj.name = categories[index].value

      // Disable the route according to categories.js
      obj.isDisabled = categories[index].disabled
      //If the object from [categories] has a route property,
      // This route is supposed to go to an explore page for that category or some other page
      if (categories[index].route) {
        obj.route = categories[index].route
        return { ...obj }
      }

      // If there are no products for that category from firebase, disable the route
      // And disable the route
      if (products.empty) {
        obj.isDisabled = true
        return { ...obj }
      }

      if (categories[index].value === 'Accessories') {
        // TODO:Please refactor this, it was meant to be a very quick fix
        const allSubCategories = products.docs.map(
          (doc) => doc.data().subCategory
        )
        const allSubCategoriesLinks = products.docs.map(
          (doc) => doc.data().linkSubCategory
        )

             const allUniqueSubCategories = [...new Set(allSubCategories)].sort(
          (a, b) => a.localeCompare(b)
        )
        const allUniqueSubCategoriesLinks = [
          ...new Set(allSubCategoriesLinks),
        ].sort((a, b) => a.localeCompare(b)) 
/* 
        const allUniqueSubCategories = [...new Set(allSubCategories)]

        const allUniqueSubCategoriesLinks = [...new Set(allSubCategoriesLinks)] */

        const routes = allUniqueSubCategoriesLinks.map((subcategory, index) => {
          return {
            route: `/accessories/${subcategory}/`,
            name: allUniqueSubCategories[index],
            isDisabled: false,
          }
        })

        obj.subMenu = [...routes]
        return obj //return the whole object here, you'll need a deep copy moving forward
      }

      if (categories[index].value === 'Collections') {
        // TODO:Please refactor this, it was meant to be a very quick fix
        const allSubCategories = products.docs.map(
          (doc) => doc.data().subCategory
        )
        const allSubCategoriesLinks = products.docs.map(
          (doc) => doc.data().linkSubCategory
        )

        const allUniqueSubCategories = [...new Set(allSubCategories)]
        const allUniqueSubCategoriesLinks = [...new Set(allSubCategoriesLinks)]

        const routes = allUniqueSubCategoriesLinks.map((subcategory, index) => {
          return {
            route: `/collections/${subcategory}/`,
            name: allUniqueSubCategories[index],
            isDisabled: false,
          }
        })
        obj.subMenu = [...routes]
        return obj //return the whole object here, you'll need a deep copy moving forward
      }

      if (categories[index].value === 'Yearly Planners') {
        // TODO:Please refactor this, it was meant to be a very quick fix
        const allSubCategories = products.docs.map(
          (doc) => doc.data().subCategory
        )
        const allSubCategoriesLinks = products.docs.map(
          (doc) => doc.data().linkSubCategory
        )

        const allUniqueSubCategories = [...new Set(allSubCategories)]
        const allUniqueSubCategoriesLinks = [...new Set(allSubCategoriesLinks)]

        const routes = allUniqueSubCategoriesLinks.map((subcategory, index) => {
          return {
            route: `/yearly-planners/${subcategory}/`,
            name: allUniqueSubCategories[index],
            isDisabled: false,
          }
        })
        obj.subMenu = [...routes]
        return obj //return the whole object here, you'll need a deep copy moving forward
      }

      // At this point, it's probably supposed to be a dropdown with options that go directly to that option's product page
      const routes = products.docs.map((doc) => {
        return {
          route: `/${doc.data().linkCategory}/${doc.data().linkName}/`,
          name: doc.data().name,
        }
      })
      obj.subMenu = [...routes]

      return { ...obj }
    })

    /* Somehow this modifies the original array (p) which is want we want */
    /* But I'm not sure how it works */
    /* This snippet will order the nav bar items under 'Accessories' according to the user-defined order in the firestore document */
    /* admin/subcategories */
    const subCatMap = subcategories.map((s) => s.sbcat)
    const accessories = p.filter((x) => x.name === 'Accessories')
    accessories[0].subMenu.sort(function (a, b) {
      return subCatMap.indexOf(a.name) - subCatMap.indexOf(b.name)
    })
    
    this.routes = [...p]
  },
  fetchOnServer: process.env.NODE_ENV !== 'development',
}
